import React from "react";
import Helmet from "react-helmet";
import PageBannerA from "../fragments/PageBannerA";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

const Title = styled.h3`
	color: var(--ys-teal);
`;

const Text = styled.p`
	display: block;
	& ul {
		padding-top: 1rem;
	}
`;

const Accent = styled.span`
	font-weight: 600;
	color: var(--ys-teal);
`;

const Italic = styled.i``;

export default function Delivery() {
	return (
		<>
			<Helmet>
				<title>Delivery - Yellow Print</title>
				<meta
					name="description"
					content="Leading printing and graphic design company based in Battersea. Our online print shop includes all promotional and marketing print. Order online at the best prices."
				/>
			</Helmet>
			<PageBannerA
				line1="Fast"
				line2="Delivery"
				img="./img/delivery/delivery-banner.jpg"
				imgTiny="./img/delivery/delivery-banner-tiny.jpg"
				width="23.5rem"
			/>
			<Container
				style={{
					margin: "6rem auto",
				}}
			>
				<Row>
					<Col lg={9}>
						<Title>NEXT DAY DELIVERY</Title>
						<Text>
							<Accent>Next-day delivery is free</Accent> to all UK mainland addresses on an
							overnight courier service (usually DPD), which arrives anytime between 8am – 6pm. We
							also offer a pre-12 service which is £12 + VAT.
						</Text>
						<Text>
							<Italic>Minimum order value is £20.</Italic>
						</Text>
						<Title>SAME-DAY DELIVERY</Title>
						<Text>
							<Accent>Same-day delivery is free</Accent> in the following areas: Balham, Battersea,
							Clapham, Southfields, Earlsfield, East Sheen, Fulham, Putney, Wandsworth Town and
							Wimbledon.
						</Text>
						<Text>
							<Accent>Same-day delivery is free</Accent> in the following postcodes: SW4, SW6, SW8,
							SW11, SW12, SW14, SW15, SW18 and SW19.
						</Text>
						<Text>
							We also offer same-day delivery to the rest of London - just email{" "}
							<a href="mailto:info@yellowprint.co.uk">info@yellowprint.co.uk</a> or call us on 020
							8875 0332 for a quote.
						</Text>
						<Text>
							<Italic>Minimum order value is £20.</Italic>
						</Text>
						<Title>SAME-DAY PRINT & COLLECTION</Title>
						<Text>
							We offer same-day turnaround on the following products, if you place your order with
							final artwork by <Accent>11am</Accent>:
							<ul>
								<li>Document Printing</li>
								<li>Bound Documents</li>
								<li>Booklets & Brochures</li>
								<li>Business Cards</li>
								<li>Flyers</li>
								<li>Wedding Stationery</li>
								<li>Posters & Plans</li>
							</ul>
						</Text>
						<Text>
							<Italic>Minimum order value is £20.</Italic>
						</Text>
						<Text>
							All products are subject to final spec and quantities. Just email{" "}
							<a href="mailto:info@yellowprint.co.uk">info@yellowprint.co.uk</a> or call us on 020
							8875 0332 for more information about same-day print and delivery.
						</Text>
						<Text>
							Collection from our production centre and offices in Battersea – River Reach, Gartons
							Way, London SW11 3SX, Monday – Friday, 8am – 5pm.
						</Text>
					</Col>
				</Row>
			</Container>
		</>
	);
}
