import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { navigation } from "../../data/navigation";

const MenuWrapper = styled.ul`
	display: flex;
	justify-content: center;
	padding: 0;
	margin: 0;
	list-style: none;
`;

const SubmenuWrapper = styled.ul`
	position: absolute;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	background: white;
	z-index: 4;
	list-style: none;
	padding: 0;
	margin-left: -0.55rem;
	margin-top: 0.235rem;
	padding-bottom: 0.5rem;
	opacity: 0;
	pointer-events: none;
	transition: 0.3s;
	box-shadow: 0px 0px 5px 2px #0000001a;
`;

const MenuItem = styled.li`
	padding-right: 2rem;
	text-transform: uppercase;
	font-size: 0.7rem;
	font-weight: 600;
	display: inline-block;
	padding: 0.3rem 0.55rem 0.25rem;
	cursor: pointer;
	&:hover {
		background: var(--ys-teal);
		color: white;
	}
	&:hover ${SubmenuWrapper} {
		opacity: 1;
		pointer-events: all;
		transition: 0.3s;
	}
	&:hover > a {
		color: white !important;
	}
`;

const SubmenuItem = styled(Link)`
	color: black;
	margin: 0.5rem 0.5rem 0px;
	height: 2.5rem;
	cursor: pointer;

	padding-right: 1rem;
	&:hover {
		background: var(--ys-yellow);
	}
`;

const ThumbImg = styled.img`
	width: 2.5rem;
	margin-right: 0.5rem;
`;

export default function DesktopNav() {
	return (
		<MenuWrapper>
			{navigation.map((item) => (
				<MenuItem key={item.title}>
					<Link to={item.link || ""}>{item.title}</Link>
					{item.submenu && (
						<SubmenuWrapper>
							{item.submenu.map((submenu, index) => (
								<SubmenuItem key={index} to={submenu.link}>
									<span>
										<ThumbImg src={submenu.img_menu}></ThumbImg>
									</span>
									<span>{submenu.title}</span>
								</SubmenuItem>
							))}
						</SubmenuWrapper>
					)}
					{item.submenu && <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: "0.25rem" }} />}
				</MenuItem>
			))}
		</MenuWrapper>
	);
}
