import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./fragments/common/Header";
import Footer from "./fragments/common/Footer";
import Home from "./pages/Home";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// Pages
import ServiceSingle from "./pages/printselection/ServiceSingle";
import PrintSolution from "./pages/PrintSolution";
import Delivery from "./pages/Delivery";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/aboutus/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";

// Data
import { services } from "./data/services";

export default class App extends Component {
	render() {
		return (
			<Router>
				<Header />

				<Switch>
					{services.print_selection.map((service) => (
						<Route exact path={`/${service.link}`} component={ServiceSingle} key={service.link} />
					))}

					<Route exact path="/contact-us" component={ContactUs} />
					<Route exact path="/print-solution" component={PrintSolution} />
					<Route exact path="/delivery" component={Delivery} />
					<Route exact path="/about-us" component={AboutUs} />
					<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					<Route exact path="/terms-and-conditions" component={TermsConditions} />
					<Route exact path="/" component={Home} />

					<Route path="*">
						<div style={{ padding: "10rem", textAlign: "center" }}>
							<Loader type="Grid" color="#ffd800" height={80} width={80} timeout={4000} />
						</div>
					</Route>
				</Switch>

				<Footer />
			</Router>
		);
	}
}
