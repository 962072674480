import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import device from "../common/device";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TestimonialsSlider from "../TestimonialsSlider";
import styled from "styled-components";
import Seperator from "./Seperator";
import { ElfsightWidget } from "react-elfsight-widget";

const MidLogos = styled.div`
	text-align: center;
`;

const MidLogosImg = styled.img`
	width: 23rem;
	@media ${device.md} {
		width: 100%;
	}
`;

const Credentials = styled.p`
	text-align: center;
	color: var(--ys-grey);
	padding: 0.25rem;
	opacity: 0.7;
`;

const BrandLogoWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	padding-bottom: 3rem;
	padding-top: 1rem;
	@media ${device.md} {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const BrandLogoSingle = styled.div`
	padding: 0.9rem;
`;

const GoogleReviewsLink = styled.a`
	cursor: pointer;
`;

const GoogleReviewsLogo = styled.img`
	width: 8rem;
	display: block;
	margin: 0 auto;
	filter: contrast(0.5) opacity(0.75);
`;

const FooterBottom = styled.div`
	background: var(--ys-black);
	padding: 1rem 0 0.1rem 0;
`;

const MenuItem = styled.div`
	color: white;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 300;
	padding-right: 1rem;
	@media ${device.md} {
		display: block;
	}
`;

const ItemSeperator = styled.span`
	margin-right: 1rem;
	color: var(--ys-yellow);
	@media ${device.md} {
		color: var(--ys-black);
	}
`;

class Footer extends Component {
	render() {
		const BrandLogos = [
			// [{ alt: "Discovery Channel" }, { link: "./img/common/discovery.png" }],
			// [{ alt: "Chesney's" }, { link: "./img/common/chesneys.png" }],
			[{ alt: "Ascot" }, { link: "./img/common/ascot.png" }],
			// [{ alt: "Bringley's" }, { link: "./img/common/brinkleys.png" }],
			// [{ alt: "UK Cancer Research" }, { link: "./img/common/cancer-research.png" }],
			// [{ alt: "Coco" }, { link: "./img/common/coco.png" }],
			// [{ alt: "Ted Baker" }, { link: "./img/common/ted-baker.png" }],

			// [{ alt: "Jimmy Choo" }, { link: "./img/common/jimmy.png" }],
			[{ alt: "Royal Albert Hall" }, { link: "./img/common/royal-albert-hall.png" }],
			// [{ alt: "CTN" }, { link: "./img/common/ctn.png" }],
			[{ alt: "Wimbledon" }, { link: "./img/common/wimbledon.png" }],
			// [{ alt: "Green Tomato Cars" }, { link: "./img/common/green-tomato.png" }],
			// [{ alt: "Imperial London Hotels" }, { link: "./img/common/imperial-london-hotels.png" }],
			// [{ alt: "Johnson & Johnson" }, { link: "./img/common/johnson-johnson.png" }],

			[{ alt: "Rhubarb" }, { link: "./img/common/rhubarb.png" }],
			// [{ alt: "Shavata" }, { link: "./img/common/shavata.png" }],
			// [{ alt: "Sky Net" }, { link: "./img/common/sky-net.png" }],
			// [{ alt: "Pink" }, { link: "./img/common/pink.png" }],
			// [{ alt: "Mira Mikati" }, { link: "./img/common/mira.png" }],
			// [{ alt: "MWA" }, { link: "./img/common/mwa.png" }],
			// [{ alt: "L&Q" }, { link: "./img/common/lq.png" }],

			[{ alt: "BBC" }, { link: "./img/common/bbc.png" }],
			// [{ alt: "H2 Property Services" }, { link: "./img/common/h2-property-services.png" }],
			// [{ alt: "Schmidt" }, { link: "./img/common/schmidt.jpg" }],

			// [{ alt: "Bone Daddies" }, { link: "./img/common/bone-daddies.png" }],
			// [{ alt: "Battersea Power Station" }, { link: "./img/common/battersea-power-station.png" }],
			// [{ alt: "Kinleigh Folkard & Hayward" }, { link: "./img/common/kinleigh.jpg" }],
			// [{ alt: "Royal Academy of Dance" }, { link: "./img/common/royal-academy-of-dance.png" }],
			[{ alt: "Sky Garden" }, { link: "./img/common/sky-garden.png" }],
			// [{ alt: "TWM Solicitors" }, { link: "./img/common/twm-solicitors.png" }],
			[{ alt: "Artisan" }, { link: "./img/common/artisan.jpg" }],
			[{ alt: "By Word Of Mouth" }, { link: "./img/common/by-word-of-mouth.jpg" }],
			[{ alt: "Cook" }, { link: "./img/common/cook.jpg" }],
			[{ alt: "itv" }, { link: "./img/common/itv.jpg" }],

			[{ alt: "Leiths" }, { link: "./img/common/leiths.jpg" }],
			[{ alt: "Roxie" }, { link: "./img/common/roxie.jpg" }],
			[{ alt: "Gym" }, { link: "./img/common/gym.jpg" }],
			[{ alt: "Tinseltown" }, { link: "./img/common/tinseltown.jpg" }],
			[{ alt: "William Hill" }, { link: "./img/common/williamhill.jpg" }],
		];

		const FooterMenu = [
			{ page: "Terms & Conditions", link: "/terms-and-conditions" },
			{ page: "Privacy Policy", link: "/privacy-policy" },
			{ page: "Contact Us", link: "/contact-us" },
		];

		const ListBrandLogos = BrandLogos.map((item, index) => (
			<BrandLogoSingle key={index}>
				<img src={item[1].link} style={{ width: "100%" }} alt={item[0].alt} />
			</BrandLogoSingle>
		));

		const ListFooterMenu = FooterMenu.map((item, index) => (
			<MenuItem key={index}>
				<Link to={item.link}>
					<p style={{ color: "white" }}>
						{item.page === "Terms & Conditions" ? null : <ItemSeperator>|</ItemSeperator>}
						{item.page}
					</p>
				</Link>
			</MenuItem>
		));

		return (
			<div>
				<Container>
					<Row>
						<Col>
							<Seperator text="Clients" bg="#bdbdbd" col="white" />
							<BrandLogoWrapper>{ListBrandLogos}</BrandLogoWrapper>
						</Col>
					</Row>
					<Row>
						<Col style={{ overflow: "hidden" }}>
							<TestimonialsSlider />

							{/* <MidLogos>
								<GoogleReviewsLink
									href="https://www.google.com/maps/place/Yellow+Signs/@51.4657942,-0.1808475,15z/data=!4m7!3m6!1s0x0:0x8fe81c185d8ac17!8m2!3d51.4657942!4d-0.1808475!9m1!1b1"
									target="_blank"
								>
									<GoogleReviewsLogo src="./img/common/google-5-star-reviews.png" alt="logos" />
								</GoogleReviewsLink>
								<MidLogosImg src="./img/common/lower-footer-logos.jpg" alt="logos" />
							</MidLogos> */}
							<Credentials>
								Yellow Print Limited, Unit 5A River Reach, Gartons Way, London SW11 3SX - Registered
								in England & Wales 07904210
							</Credentials>
						</Col>
					</Row>
				</Container>
				<FooterBottom>
					<Container>
						<Row>
							<Col style={{ textAlign: "center" }}>{ListFooterMenu}</Col>
						</Row>
					</Container>
				</FooterBottom>
				{/* <ElfsightWidget widgetID="87a98e98-ef2f-46d7-bbd0-24e24932b5ab" /> */}
			</div>
		);
	}
}

export default withRouter(Footer);
