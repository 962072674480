import React, { Component } from "react";
import HeaderSimple from "../fragments/services/HeaderSimple";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class TermsConditions extends Component {
	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
		return (
			<div>
				<HeaderSimple heading="Terms and Conditions" />
				<Container>
					<Row>
						<Col>
							<ol className="terms">
								<li className="terms">
									<strong>DEFINITIONS AND INTERPRETATION</strong>
									<ol className="terms">
										<li className="terms">
											In these Conditions the following terms have the following meanings:
											<p>
												<br />
												<strong>Yellow Print</strong>
												<br />
												Yellow Print Limited whose principal place of business is at Unit 5A River
												Reach, Gartons Way, London SW11 3SX
											</p>
											<p>
												<strong>Conditions</strong>
												<br />
												the terms and conditions set out in this document;
											</p>
											<p>
												<strong>Contract</strong>
												<br />
												any agreement between Yellow Print and the Customer for the sale and
												purchase of the Goods and Services incorporating these Conditions;
											</p>
											<p>
												<strong>Customer</strong>
												<br />
												the person who agrees to purchase the Goods and Services from Yellow Print
												subject to these Conditions;
											</p>
											<p>
												<strong>Deposit</strong>
												<br />
												the amount of the deposit against the Price to be paid by the Customer in
												advance;
											</p>
											<p>
												<strong>Goods</strong>
												<br />
												the goods which Yellow Print is to supply to the Customer;
											</p>
											<p>
												<strong>Lead Time</strong>
												<br />
												the time after the Customer's final confirmation of the Specification within
												which Yellow Print estimates that the Goods will be delivered to and/or the
												Services will be performed;
											</p>
											<p>
												<strong>Order</strong>
												<br />
												the Customer's order for the supply of the Goods and/or Services;
											</p>
											<p>
												<strong>Price</strong>
												<br />
												the price to be paid by the Customer under the Contract;
											</p>
											<p>
												<strong>Quote</strong>
												<br />
												Yellow Print' quote for the supply of the Goods and/or Services;
											</p>
											<p>
												<strong>Services</strong>
												<br />
												the services which Yellow Print is to supply to the Customer including in
												particular installation of the Goods at the Supply Address;
											</p>
											<p>
												<strong>Specification</strong>
												<br />
												the specification for the Goods and / or the Services provided or agreed in
												advance by Yellow Print (and subsequently finalised by agreement) together
												with all applicable legislative and regulatory requirements for the Goods
												and / or Services in question;
											</p>
											<p>
												<strong>Supply Address</strong>
												<br />
												the address for delivery of the Goods and/or performance of the Services
												which shall be the Customer's principal place of business unless specified
												otherwise;
											</p>
											<p>
												<strong>Working Day</strong>
												<br />
												any day which is not a Saturday, Sunday or public holiday in England.
											</p>
										</li>
									</ol>
								</li>
								<li className="terms">
									<strong>BASIS OF CONTRACT</strong>
									<ol className="terms">
										<li className="terms">
											These Conditions apply to all contracts for the supply of Goods and Services
											entered into by Yellow Print to the exclusion of all others including, without
											limitation, any terms and conditions appearing on or referred to in the
											Customer’s purchase order.
										</li>
										<li className="terms">
											Any variation to this contract must be noted in writing.
										</li>
										<li className="terms">
											Yellow Print' employees or agents are not authorised to make any
											representations concerning the Goods or Services unless confirmed by Yellow
											Signs in writing. In entering into the Contract the Customer acknowledges that
											it does not rely on any such representations which are not so confirmed.
										</li>
										<li className="terms">
											Any advice or recommendation given by Yellow Print or its employees to the
											Customer or its employees or agents as to the application, storage or use of
											the Goods and which is not confirmed in writing by Yellow Print is followed or
											acted upon entirely at the Customer's own risk and accordingly Yellow Print
											shall not be liable for any issues arising from such advice.
										</li>
										<li className="terms">
											Any typographical, clerical or other error or omission in any sales
											literature, Quote, price list, invoice or other document or information issued
											by Yellow Print shall be subject to correction without any liability on the
											part of Yellow Print.
										</li>
									</ol>
								</li>
								<li className="terms">
									<strong>SALE AND PURCHASE</strong>
									<ol className="terms">
										<li className="terms">
											Yellow Print agrees to supply the Goods and Services to the Customer on the
											terms of these Conditions
										</li>
										<li className="terms">
											Each Order or acceptance of a Quote shall be deemed to be an offer by the
											Customer to purchase the Goods and/or Services subject to these Conditions and
											shall be binding on the Customer but shall not bind Yellow Print until Yellow
											Signs has accepted that Order or acceptance of Quote in writing.
										</li>
										<li className="terms">
											The Customer shall ensure that the terms of its Order and any applicable
											specification are complete and accurate.
										</li>
										<li className="terms">
											Yellow Print shall be under no obligation to proceed with its obligations
											under the Contract until
											<ol className="terms">
												<li className="terms">
													Yellow Print shall have received the Deposit or Full Payment; and
												</li>
												<li className="terms">
													the Customer shall have provided all designs and artwork necessary for the
													Specification
												</li>
												<li className="terms">
													the Customer shall have given its final approval of the Specification.
												</li>
											</ol>
											Approval of the Specification shall be deemed to be conclusive evidence of the
											Customer's acceptance of the incorporation of these Conditions into the
											Contract.
										</li>
									</ol>
								</li>

								<li className="terms">
									<strong>DESCRIPTION</strong>
									<ol className="terms">
										<li className="terms">
											The quantity and description of the Goods and/or Services shall be as set out
											in the Quote or Yellow Print' acceptance of Order.
										</li>
										<li className="terms">
											All samples, drawings, descriptive matter, specifications and advertising
											issued by Yellow Print and any descriptions or illustrations contained in
											Yellow Print' catalogues or brochures are issued or published for the sole
											purpose of giving an approximate idea of the Goods and/or Services described
											in them. They shall not form part of the Contract.
										</li>
										<li className="terms">
											If the Goods are to be manufactured or any process is to be applied to them by
											Yellow Print in accordance with the specifications submitted by the Customer,
											or if the Goods are to be marked with any trade mark or any words or images at
											the request of the Customer, the Customer shall indemnify and hold Yellow
											Signs harmless against all damages, costs and expenses awarded against or
											incurred by Yellow Print or agreed to be paid by Yellow Print in settlement or
											in connection with any claim for infringement of any intellectual property
											rights, misuse of confidential information, defamation or otherwise as a
											result of use of such specifications or marking.
										</li>
										<li className="terms">
											Yellow Print reserves the right to make any changes in the specifications of
											the Goods and/or Services which are required to conform with any applicable
											safety standard or other requirements or which do not materially affect their
											quality or performance.
										</li>
									</ol>
								</li>

								<li className="terms">
									<strong>PERFORMANCE OF THE CONTRACT</strong>
									<ol className="terms">
										<li className="terms">
											Yellow Print shall use its reasonable efforts to supply the Goods and/or
											Services to the Customer at the Supply Address within the Lead Time, but
											timings are not guaranteed. The Customer shall provide safe and clear
											unobstructed access to the Supply Address.
										</li>
										<li className="terms">
											Yellow Print shall be entitled to supply the Goods and/or Services in
											instalments in which case each instalment shall be treated as an entirely
											separate contract and any default or breach by Yellow Print in respect of any
											such instalment shall not entitle the Customer to cancel any other instalment
											or treat the Contract as a whole as repudiated. Signature of Yellow Print'
											delivery note by a person reasonably appearing to be the Customer's
											representative shall be conclusive proof of delivery.
										</li>
										<li className="terms">
											The Customer shall inspect the Goods on delivery and if the Goods are damaged
											on delivery or less than the correct amount of the Goods is delivered then,
											unless the Customer puts a note to such effect on the delivery note and
											notifies Yellow Print within 2 Working Days of delivery, confirming the
											notification in writing to Yellow Print within 5 Working Days of delivery, no
											claim against Yellow Print may be made in respect of damage to or short
											delivery of such Goods. Except where such notification is given, the Customer
											shall be deemed to accept the Goods on delivery notwithstanding any late
											delivery by Yellow Print.
										</li>
										<li className="terms">
											The Customer will have no claim in respect of any alleged non-delivery of the
											Goods unless it gives written notice to Yellow Print which is received by
											Yellow Print within 7 Working Days of the date of the invoice in respect of
											those Goods.
										</li>
										<li className="terms">
											Where the Services include installation of the Goods, the Customer shall
											provide safe and clear unobstructed access to the place of installation and
											such other supplies and facilities as Yellow Print requires in order to
											perform the Services. If the Services include electrical installation the
											Customer shall provide a suitable electrical feed within 1 metre of the place
											where the Goods are to be installed. It is the responsibility of the Customer
											that all electrical work is checked and certified by a qualified electrician.
											The Customer is responsible for ensuring that the building or structure to
											which they are to be attached is suitable and where the Goods are to be fixed
											to an existing subfascia that the subfascia is suitable and of sound
											construction and condition. When installing the Goods onto an existing
											subfascia Yellow Print will not intrude into or dismantle the subfascia but
											will install the Goods onto the subfascia on the basis that the subfascia is
											sound and suitable. Where the Customer fails in such obligations Yellow Print
											shall be entitled at its option to:
											<ol className="terms">
												<li className="terms">
													terminate the Contract in relation to the relevant Goods; or
												</li>
												<li className="terms">
													continue with the supply of the Goods and charge for such costs (if any)
													as it has incurred at its standard rates in relation to such installation
													(up to a maximum of the amount it would have charged for the installation)
													in which case the Contract shall be deemed to be amended so as to limit
													the relevant Services to those actually provided.
												</li>
												<li className="terms">
													where the Services include installation of the Goods, Signature of Yellow
													Signs' installation note by a person reasonably appearing to be the
													Customer's representative shall be conclusive proof of their satisfactory
													installation.
												</li>
											</ol>
										</li>
									</ol>
								</li>

								<li className="terms">
									<strong>PRICE AND PAYMENT</strong>
									<ol className="terms">
										<li className="terms">
											Yellow Print reserves the right, by giving notice to the Customer at any time
											before supply, to increase the Price to reflect any increase in the cost to
											Yellow Print which is due to any factor beyond its control, any change in
											delivery dates, quantities or specifications for the Goods and Services which
											is requested by the Customer or any delay caused by any instructions of the
											Customer or failure of the Customer to give Yellow Print adequate access,
											information or instructions.
										</li>
										<li className="terms">The Price is exclusive of any applicable VAT.</li>
										<li className="terms">
											The Customer shall make payment to Yellow Print in respect of all invoices in
											full by the due date displayed on the invoice.
										</li>
										<li className="terms">
											The Customer shall make all payments due under the Contract in full without
											any deduction whether by way of set-off, counterclaim, discount, abatement or
											otherwise.
										</li>
										<li className="terms">
											All payments shall be applied to invoices and to Goods and/or Services listed
											in such invoices in the Order determined in its discretion by Yellow Print.
										</li>
										<li className="terms">
											If at any time the credit standing of the Customer has in the reasonable
											opinion of Yellow Print been impaired, Yellow Print may suspend the further
											provision of goods and/or services to the Customer without incurring any
											liability until arrangements as to payment or credit have been established
											which are reasonably satisfactory to Yellow Print.
										</li>
										<li className="terms">
											If full payment is not received by Yellow Print by the due date then without
											prejudice to its rights Yellow Print shall be entitled:
											<ol className="terms">
												<li className="terms">to sue for the entire Price; and/or</li>
												<li className="terms">
													to suspend the further provision of goods and/or services to the Customer
													without incurring any liability; and/or
												</li>
												<li className="terms">
													to terminate the Contract without incurring any liability; and/or
												</li>
												<li className="terms">
													to require the immediate return to Yellow Print of all Goods agreed to be
													sold by Yellow Print to the Customer in which the property has not passed
													to the Customer, or to remove the Goods supplied by Yellow Print;
												</li>
												<li className="terms">
													to recover its reasonable costs incurred in recovering payment from the
													Customer together with its costs and expenses in recovering the Goods.
												</li>
											</ol>
										</li>
									</ol>
								</li>

								<li className="terms">
									<strong>PRICE PROMISE</strong>
									<ol className="terms">
										<li className="terms">
											Yellow Print will match any competitor’s price, provided that;
											<ol className="terms">
												<li className="terms">
													A written quote from the competitor is supplied to Yellow Print;
												</li>
												<li className="terms">The products/materials are like for like</li>
												<li className="terms">The products/materials are in stock</li>
												<li className="terms">
													The competitor and Yellow Print operate in the Supply Address area
												</li>
											</ol>
										</li>
									</ol>
								</li>

								<li className="terms">
									<strong>WARRANTY AND LIABILITY</strong>
									<ol className="terms">
										<li className="terms">
											Yellow Print warrants that the Goods will correspond in all material respects
											with the Specifications for a period of 12 months from the date of delivery
											provided that:
											<ol className="terms">
												<li className="terms">
													Yellow Print shall have no liability in respect of any defects in the
													Goods arising from any drawing, design or specifications supplied by or on
													behalf of the Customer;
												</li>
												<li className="terms">
													Yellow Print shall have no liability in respect of any Goods installed by
													the Customer or a third party;
												</li>
												<li className="terms">
													Yellow Print shall have no liability in respect of any defect arising from
													fair wear and tear, negligence or wilful damage by the Customer or persons
													using the Goods, abnormal working conditions, failure to follow Yellow
													Signs' instructions (whether oral or in writing), or misuse or alteration
													or repair of the Goods without Yellow Print' approval;
												</li>
												<li className="terms">
													Yellow Print shall have no liability if the total Price has not been paid
													by the due date for payment;
												</li>
												<li className="terms">
													any such failure to meet any such specification shall be notified to
													Yellow Print in writing as soon as reasonably possible after the Customer
													discovers such non-conformity.
												</li>
											</ol>
										</li>
										<li className="terms">
											Yellow Print warrants that the Services will be performed in accordance with
											the Specifications by appropriately qualified and experienced personnel with
											reasonable care and skill provided that:
											<ol className="terms">
												<li className="terms">
													Yellow Print shall have no liability from compliance with any
													specification supplied by or requirement of the Customer;
												</li>
												<li className="terms">
													Yellow Print shall have no liability is respect of failures and problems
													resulting from faults with the building, structure or subfascia to which
													Yellow Print fixed the Goods where such faults were not apparent at the
													time of installation and had not been notified to Yellow Print in writing
													by the Customer.
												</li>
												<li className="terms">
													Yellow Print shall have no liability if the total Price has not been paid
													by the due date for payment; and
												</li>
												<li className="terms">
													any such failure to meet any such specification shall be notified to
													Yellow Print in writing as soon as reasonably possible after the Customer
													discovers such non-conformity.
												</li>
											</ol>
										</li>
										<li className="terms">
											In the event of any valid claim under clauses 7.1 or 7.2 above being made by
											the Customer, the Customer shall afford Yellow Print a reasonable opportunity
											to inspect the Goods or the results of the Services at a time convenient to
											Yellow Print and Yellow Print shall be entitled to replace or repair the Goods
											(or the part in question) or re-perform the Services free of charge or, at
											Yellow Print' sole discretion, refund to the Customer the Price (or a
											proportionate part of the Price as appropriate). Yellow Print shall have no
											further liability to the Customer.
										</li>
										<li className="terms">
											All warranties, conditions and other terms implied by statute or common law
											(save for the conditions implied by section 12 of the Sale of Goods Act 1979
											and section 2 of the Supply of Goods and Services Act 1982) are, to the
											fullest extent permitted by law, excluded from the Contract.
										</li>
										<li className="terms">
											Yellow Print does not seek to exclude or limit its liability for fraud or
											fraudulent misrepresentation or death or personal injury resulting from
											negligence, fraud or for any other matter in respect of which it would be
											unlawful for Yellow Print to exclude or limit its liability.
										</li>
										<li className="terms">
											Yellow Print shall be liable to the Customer for any direct physical damage
											(other than death or personal injury) to the extent that it results from the
											negligence of Yellow Print up to a maximum of £1 million.
										</li>
										<li className="terms">
											Except pursuant to clause 7.5 above, Yellow Print shall not in any event be
											liable for:any loss of profits;
											<ol className="terms">
												<li className="terms">loss or depletion of goodwill;</li>
												<li className="terms">
													loss of anticipated savings, business opportunity or data; or
												</li>
												<li className="terms">
													for any indirect, special or consequential loss or damages;
												</li>
											</ol>
											howsoever arising in connection with or arising out of the provision,
											performing, furnishing, functioning or use of the Goods and/or Services, or
											any item or service provided whether in contract, strict liability, tort
											(including without limitation, negligence) and whether Yellow Print knew or
											had reason to know of the same, and shall not be liable for any other damages
											except as provided in the Contract.
										</li>
										<li className="terms">
											Except pursuant to clause 7.5 above in no event shall Yellow Print' liability
											in respect of any of the Goods and/or Services where such a claim is for
											breach of contract, strict liability or tort (including without limitation,
											negligence) or otherwise exceed the price paid for those Goods and/or
											Services.
										</li>
									</ol>
								</li>

								<li className="terms">
									<strong>THIRD PARTY RIGHTS</strong>
									<ol className="terms">
										<li className="terms">
											Yellow Print shall at its expense defend any action against the Customer and
											pay all damages and costs awarded against the Customer (except to the extent
											that the Customer is entitled to recover such sums under any policy of
											insurance) based on a claim that any of the Goods constitute an infringement
											of any patent or copyright or other intellectual property rights of the United
											Kingdom or on a claim of misuse of any confidential information belonging to
											any third party (except to the extent that such infringement or misuse results
											from following the Customer's specifications or other requirements) ("a
											Claim") provided that:
											<ol className="terms">
												<li className="terms">
													Yellow Print shall be notified promptly in writing by the Customer of any
													notice of a Claim;
												</li>
												<li className="terms">
													Yellow Print shall have the sole control of the defense of any Claim and
													all negotiations for settlement or compromise of the Claim and the
													Customer shall not make any statement or enter into any settlement
													negotiations or compromise in relation thereto;
												</li>
												<li className="terms">
													the Customer shall allow its name to be used in proceedings if necessary
													and provide all reasonable assistance in defending any action; and
												</li>
												<li className="terms">
													the Customer shall take all steps reasonably possible to mitigate or
													reduce any compensation and costs which may be awarded against it as a
													result of a Claim.
												</li>
											</ol>
										</li>
										<li className="terms">
											If a Claim is successful or Yellow Print considers that it is likely to be
											successful, Yellow Print may, at its option or as part of a settlement or
											compromise, procure for the Customer the right to continue using the Goods,
											modify the Goods so that they are non-infringing or terminate the Contract in
											so far as it applies to those Goods subject to the Claim, in which latter case
											Yellow Print shall refund to the Customer the price paid for such Goods less
											depreciation on a straight line basis over the life of the Goods as determined
											by Yellow Print.
										</li>
										<li className="terms">
											In no event shall Yellow Print have any liability under this clause with
											respect to any claim based on the use of the Goods in combination with any
											other product or equipment not supplied by Yellow Print.
										</li>
										<li className="terms">
											This clause states the entire obligation and liability of Yellow Print with
											respect to infringement of intellectual property rights and misuse of
											confidential information.
										</li>
									</ol>
								</li>

								<li className="terms">
									<strong>TERMINATION</strong>
									<ol className="terms">
										<li className="terms">
											Yellow Print shall be entitled to terminate the Contract immediately by notice
											in writing to the Customer if:
											<ol className="terms">
												<li className="terms">
													the Customer fails to pay the Deposit or provide all designs and artwork
													necessary for the Specification within 5 Working Days after the date of
													the Contract or fails to agree the final Specification within 10 Working
													Days after the date of the Contract (or in each case within such longer
													period as the parties may agree);
												</li>
												<li className="terms">
													the Customer commits an irremediable breach of the Contract, persistently
													repeats a remediable breach or commits any remediable breach and fails to
													remedy it within 30 days of receipt of notice of the breach requiring
													remedy of the same; or the Customer makes an arrangement with or enters
													into a compromise with its creditors, becomes the subject of a voluntary
													arrangement, receivership, administration, liquidation or winding up, is
													unable to pay its debts or otherwise becomes insolvent or suffers or is
													the subject of any distraint, execution, event of insolvency or event of
													bankruptcy or any other similar process or event, whether in the United
													Kingdom or elsewhere; or
												</li>
												<li className="terms">
													the Customer ceases or threatens to cease to carry on business; or
												</li>
												<li className="terms">
													there is at any time a material change in the management, ownership or
													control of the Customer; or
												</li>
												<li className="terms">
													if Yellow Print reasonably apprehends that any of the events specified in
													clauses 10.1.2 to 10.1.4 are about to occur in relation to the Customer
													and notifies the Customer accordingly.
												</li>
											</ol>
										</li>
										<li className="terms">
											In the event of termination by Yellow Print pursuant to clause 10.1 above
											then, without prejudice to any other right or remedy available to Yellow
											Signs, Yellow Print shall be entitled to suspend any further supply under the
											Contract and under any other contract between the parties without any
											liability to the Customer and, if the Goods and/or Services have already been
											supplied but not paid for, the Price shall become immediately due and payable
											notwithstanding any previous agreement or arrangement to the contrary.
										</li>
									</ol>
								</li>

								<li className="terms">
									<strong>FORCE MAJEURE</strong>
									<ol className="terms">
										Yellow Print reserves the right to defer the date of supply of the Goods and/or
										Services or to cancel the Contract without incurring any liability or reduce the
										volume of the Goods ordered by the Customer (without liability to the Customer)
										if it is prevented from or delayed in the carrying on of its business due to
										circumstances beyond the reasonable control of Yellow Print including, without
										limitation, acts of God, governmental actions, war or national emergency, acts
										of terrorism, protests, riot, civil commotion, fire, explosion, flood, epidemic,
										lock-outs, strikes or other labour disputes (whether or not relating to either
										party's workforce), or restraints or delays affecting carriers or inability or
										delay in obtaining supplies of adequate or suitable materials, provided that, if
										the event in question continues for a continuous period in excess of 90 days,
										the Customer shall be entitled to give notice in writing to Yellow Print to
										terminate the Contract.
									</ol>
								</li>

								<li className="terms">
									<strong>GENERAL</strong>
									<ol className="terms">
										<li className="terms">
											The remedies available to Yellow Print under the Contract shall be without
											prejudice to any other rights, either at common law or under statute, which it
											may have against the Customer.
										</li>
										<li className="terms">
											The failure or delay of Yellow Print to enforce or to exercise, at any time,
											or for any period of time, any term of or any right, power or privilege
											arising pursuant to the Contract does not constitute and shall not be
											construed as a waiver of such term or right and shall in no way affect its
											right later to enforce or exercise it, nor shall any single or partial
											exercise of any remedy, right, power or privilege preclude any further
											exercise of the same or the exercise of any other remedy, right, power or
											privilege.
										</li>
										<li className="terms">
											The invalidity or unenforceability of any term of, or any right arising
											pursuant to the Contract shall not in any way affect the remaining terms or
											rights which shall be construed as if such invalid or unenforceable term or
											right did not exist.
										</li>
										<li className="terms">
											Any notice or written communication required or permitted to be served on or
											given to either party under the Contract shall be delivered by hand or sent by
											recorded delivery mail to the other party at its address set out above or to
											such other address which it has been previously notified to the sending party
											and shall be deemed to have been given on the day of delivery.
										</li>
										<li className="terms">
											The Contract is personal to the Customer and the Customer may not assign,
											transfer, sub- contract or otherwise part with the Contract or any right or
											obligation under it without the prior written consent of Yellow Print. Yellow
											Signs shall be entitled to assign, transfer, sub-contract or otherwise part
											with the whole or any part of the Contract or any right or obligation under it
											to any third party.
										</li>
										<li className="terms">
											Clause headings are purely for ease of reference and do not form part of or
											affect the interpretation of the Contract.
										</li>
										<li className="terms">
											The Contract contains all the terms agreed by the parties relating to the
											subject matter of the Contract and supersedes any prior agreements,
											understandings or arrangements between them, whether oral or in writing, and
											no representation (unless made fraudulently), undertaking or promise shall be
											taken to have been given or been implied from anything said or written in
											negotiations between the parties prior to the Contract except as set out in
											the Contract.
										</li>
										<li className="terms">
											No variation or amendment to the Contract shall be effective unless in writing
											signed by authorised representatives of the parties.
										</li>
										<li className="terms">
											The parties to the Contract do not intend that any term of the Contract shall
											be enforceable by virtue of the Contracts (Rights of Third Parties) Act 1999
											by any person that is not a party to it.
										</li>
										<li className="terms">
											The formation, existence, construction, validity and performance and all
											aspects of the Contract (including any associated non-contractual disputes or
											claims) are governed by the laws of England and the parties accept the
											exclusive jurisdiction of the English Courts.
										</li>
									</ol>
								</li>
							</ol>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default TermsConditions;
