import React, { Component } from 'react';
import {
  // Link,
  withRouter
} from 'react-router-dom'

class Body extends Component {
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(Body);