import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { navigation } from "../../data/navigation";
import MobileSubmenu from "./MobileSubmenu";

const NavWrapper = styled.nav`
	position: absolute;
	background: var(--ys-yellow);
	top: 2.1rem;
	left: 0;
	z-index: 4;
	width: 100vw;
	overflow-y: scroll;
	height: 96vh;
	opacity: ${(props) => (props.isOpen ? "1" : "0")};
	pointer-events: ${(props) => (props.isOpen ? "all" : "none")};
	transition: 0.3s;
	padding: 0 1rem;
`;

const BurgerBtn = styled.div`
	position: absolute;
	top: 0.5rem;
`;

const MenuItem = styled.li`
	text-transform: uppercase;
	font-weight: 600;
	list-style: none;
	padding-top: 1rem;
`;

export default function MobileNav() {
	const [menuOpen, setMenuOpen] = useState(false);

	const handleBurgerClick = () => {
		setMenuOpen(!menuOpen);
	};

	useEffect(() => {
		// console.log("recording this");
		// window.addEventListener(
		// 	"scroll",
		// 	function () {
		// 		console.log(window.scrollY);
		// 	},
		// 	false
		// );
		// window.addEventListener("touchmove", () => {
		// 	console.log(window.scrollY);
		// });
		if (menuOpen) {
			document.body.classList.add("no-scroll");
		}
		if (!menuOpen) {
			document.body.classList.remove("no-scroll");
		}
	}, [menuOpen]);

	return (
		<>
			<BurgerBtn onClick={handleBurgerClick}>
				<FontAwesomeIcon
					icon={faBars}
					style={{
						width: "1.4rem",
						height: "1.4rem",
					}}
				/>
			</BurgerBtn>
			<NavWrapper isOpen={menuOpen}>
				{navigation.map((item) => (
					<div key={item.title}>
						{item.submenu ? (
							<MobileSubmenu props={item} handleCloseMenu={handleBurgerClick} />
						) : (
							<MenuItem key={item.title} onClick={handleBurgerClick}>
								<Link to={item.link}>{item.title}</Link>
							</MenuItem>
						)}
					</div>
				))}
			</NavWrapper>
		</>
	);
}
