import React, { Component, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import device from "../common/device";
import DesktopNav from "./DesktopNav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import MobileNav from "./MobileNav";

const TopWrapper = styled.div`
	background: var(--ys-teal);
`;
const MidWrapper = styled.div`
	background: rgb(0, 0, 0);
	background: linear-gradient(90deg, rgba(0, 0, 0, 1) 50%, rgba(8, 175, 164, 1) 50%);
	color: white;
	border-bottom: 2px solid var(--ys-yellow);
`;
const TopBarContent = styled.p`
	color: white;
	font-size: 0.705rem;
	text-align: center;
	padding: 0.35rem 8rem;
	margin-bottom: 0;

	@media ${device.sm} {
		padding: 0.5rem 0;
		font-size: 0.76rem;
		text-align: center;
	}

	@media ${device.xs} {
		padding: 0.5rem 0;
		font-size: 0.65rem;
		text-align: center;
		height: 2.8rem;
	}
`;

const ContactWrap = styled.div`
	background: var(--ys-teal);
	height: 100%;
	clip-path: polygon(5% 0, 101% 0%, 101% 100%, 0% 100%);
	padding: 0.25rem 0rem 1rem 0;
	max-height: 6.6rem;
	text-align: right;
	@media ${device.sm} {
		clip-path: none;
		text-align: center;
		padding: 0 0.5rem 0.5rem 0.5rem;
	}
`;

const LogoWrap = styled.div`
	background: black;
`;

const SocialWrap = styled.div`
	background: linear-gradient(175deg, rgba(255, 234, 117, 1) 11%, rgba(255, 216, 0, 1) 47%);
	height: 100%;
	clip-path: polygon(7% 0, 100% 0%, 93% 101%, 0% 101%);
	padding: 2rem 1rem 2rem 1.75rem;
	text-align: center;

	@media ${device.sm} {
		clip-path: none;
		padding: 0;
		width: 100%;
		margin: 0;
		text-align: right;
	}
`;

const MenuWrapper = styled.div`
	background: var(--ys-yellow);
	margin-top: -1px;
`;

const Logo = styled.div`
	text-align: center;
	/* padding-left: 3rem; */
	@media ${device.sm} {
		padding-left: 0;
	}
`;

const TelNo = styled.a`
	color: white !important;
	font-family: Alternate, "sans-serif";
	font-size: 2rem;
	letter-spacing: -0.45px;
`;

const Hours = styled.div`
	font-size: 0.75rem;
	margin-top: -0.4rem;
`;

const Email = styled.a`
	font-size: 0.75rem;
	margin-top: 0rem;
	color: white !important;
	display: block;
	margin-left: 2.9rem;
	@media ${device.sm} {
		margin-left: 0;
	}
`;

const MobileNotice = styled.div`
	background: var(--ys-yellow);
	color: black;
	font-size: 0.8rem;
	text-align: center;
	padding: 0.25rem;
	margin-bottom: 2rem;
`;

const logoImg = {
	width: "17rem",
	padding: "1.6rem",
};

const socialImgInsta = {
	color: "black",
	width: "1.5rem",
	height: "1.5rem",
	marginRight: "1.25rem",
	marginTop: ".45rem",
};

const socialImgFb = {
	color: "black",
	width: "1.3rem",
	height: "1.3rem",
	marginBottom: ".1rem",
	marginRight: "1rem",
};

export default function Header() {
	// Create state container for current page uri
	const [isDesktop, setIsDesktop] = useState(true);

	useEffect(() => {
		if (document.body.clientWidth < device.mid) setIsDesktop(false);
	}, []);

	return (
		<div>
			{isDesktop && (
				<TopWrapper>
					<Container>
						<Row>
							<Col>
								<TopBarContent>
									Yellow Print has permanently moved from Southfields to Battersea but we are still
									offering the same great service and even more products!
								</TopBarContent>
							</Col>
						</Row>
					</Container>
				</TopWrapper>
			)}

			<MidWrapper>
				<Container>
					<Row style={{ background: "black" }}>
						<Col lg={2} style={document.body.clientWidth < device.small ? { padding: "0" } : null}>
							<SocialWrap>
								<a
									href="https://www.facebook.com/YellowPrintSouthfields/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon style={socialImgFb} icon={faFacebookF} className="faIcon" />
								</a>
								<a
									href="https://www.instagram.com/yellowprintUK/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon style={socialImgInsta} icon={faInstagram} className="faIcon" />
								</a>
							</SocialWrap>
						</Col>
						<Col lg={8} style={{ padding: 0 }}>
							<LogoWrap>
								<Link to="/">
									<Logo>
										<img
											draggable="false"
											style={logoImg}
											alt="Yellow Signs Logo"
											src={"./img/common/yellow-signs-logo.svg"}
										/>
									</Logo>
								</Link>
							</LogoWrap>
						</Col>
						<Col
							lg={2}
							style={{
								background: "black",
								padding: 0,
							}}
						>
							<ContactWrap>
								<TelNo href="tel:02088750332">020 8875 0332</TelNo>
								<Hours>8am – 5pm / Mon – Fri</Hours>
								<Email href="mailto:info@yellowprint.co.uk">info@yellowprint.co.uk</Email>
							</ContactWrap>
						</Col>
					</Row>
				</Container>
			</MidWrapper>

			{!isDesktop && (
				<MobileNotice>
					Super-Fast Digital & Litho Print
					<br />
					Please email your order to{" "}
					<a href="mailto:info@yellowprint.co.uk">info@yellowprint.co.uk</a>
					<br />
					Minimum order value of £20
					<br />
					(we do not offer a while-you-wait service)
				</MobileNotice>
			)}

			<MenuWrapper>
				<Container style={{ marginTop: "-2px" }}>
					{isDesktop ? <DesktopNav /> : <MobileNav />}
				</Container>
			</MenuWrapper>
		</div>
	);
}
