import React, { Component } from 'react';
import device from '../common/device';
import styled from 'styled-components';

const SeperatorWrap = styled.div`
  text-align:center;
  padding:2rem 0;
`;

const SeperatorLine = styled.div`
  height: .1rem;
  width: 495px;
  display: inline-block;
  @media ${device.md} {
    display:none;
  }
`;

const SeperatorText = styled.div`
  font-family: 'Avenir', sans-serif;
  font-style:italic;
  font-size:.85rem;
  display: inline-block;
  padding: .4rem 1.25rem;
  text-transform: uppercase;
  clip-path: polygon(7% 0,100% 0%,93% 101%,0% 101%);
`;

class Seperator extends Component {
  render() {
    return (
      <SeperatorWrap>
        <SeperatorLine style={{background:`${this.props.bg}`}} />
          <SeperatorText
            style={{
            background:`${this.props.bg}`,
            color:`${this.props.col}`
            }}>
            {this.props.text}
          </SeperatorText>
        <SeperatorLine style={{background:`${this.props.bg}`}} />
      </SeperatorWrap>
    );
  }
}

export default Seperator;