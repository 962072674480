import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import device from "../fragments/common/device";
import PointerEvents from "../fragments/common/PointerEvents";
import HeroSlider from "../fragments/HeroSlider";
import Body from "../fragments/common/Body";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

import { services } from "../data/services";

const HomeContainer = styled(Container)`
	margin-top: 4rem;
	@media ${device.sm} {
		margin-top: 0rem;
	}
`;

const CardTitle = styled.h3`
	color: var(--ys-teal);
	padding-top: 1rem;
`;

const CardWrap = styled.div`
	padding-bottom: 3rem;
`;

const ThumbImg = styled.img`
	width: 100%;
	@media ${device.sm} {
		margin-top: -1rem;
	}
`;

const CardText = styled.p`
	/* min-height: 5rem; */
	margin-bottom: 0;
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	/* @media ${device.sm} {
		min-height: 3.5rem;
	} */
`;

class Home extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		const Card = (props) => {
			return (
				<Col lg={3}>
					<CardWrap>
						<a href={props.link}>
							<ThumbImg src={props.img} alt={props.title} />
						</a>
						<CardTitle>{props.title}</CardTitle>
						<CardText>{props.desc}</CardText>
						<Link to={props.link}>
							<button className="alternate">More ></button>
						</Link>
					</CardWrap>
				</Col>
			);
		};

		return (
			<Body>
				<Helmet>
					<title>Printing Services &amp; Graphic Design | Battersea - Yellow Print</title>
					<meta
						name="description"
						content="Leading printing and graphic design company based in Battersea. Our online print shop includes all promotional and marketing print. Order online at the best prices."
					/>
				</Helmet>

				<HeroSlider />
				<HomeContainer>
					<Row style={window.innerWidth < device.mid ? { marginTop: "2rem" } : null}>
						{services.print_selection.map((service) => (
							<Card
								key={service.title}
								img={service.img_grid}
								title={service.title}
								desc={service.desc_long}
								link={service.link}
							/>
						))}
					</Row>
				</HomeContainer>
			</Body>
		);
	}
}

export default withRouter(Home);
