import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import PageBannerA from "../fragments/PageBannerA";
import Helmet from "react-helmet";

const Title = styled.h3`
	color: var(--ys-teal);
	// padding-top:1rem;
`;

const Text = styled.p`
	padding-bottom: 2rem;
`;

class PrintSolution extends Component {
	render() {
		return (
			<div>
				<Helmet>
					<title>Print Solution - Yellow Print</title>
					<meta
						name="description"
						content="Leading printing and graphic design company based in Battersea. Our online print shop includes all promotional and marketing print. Order online at the best prices."
					/>
				</Helmet>
				<PageBannerA
					line1="Print"
					line2="Solution"
					img="./img/printsolution/print-solution-1.jpg"
					imgTiny="./img/printsolution/print-solution-1-tiny.jpg"
					width="22.4rem"
				/>
				<Container>
					<Row lg={3} style={{ marginTop: "4rem" }}>
						<Col sm={12}>
							<Title>PRINT SOLUTION</Title>
							<Text>
								We don’t just sell signs, we offer a complete signage solution and help to build and
								enhance your brand. We pride ourselves on asking the right questions and starting
								the process in the most effective and efficient way to ensure each client receives
								the correct and most suitable solution. Our dedicated team and large range of
								products, services and equipment allow us to match the client to the solution each
								and every time.
							</Text>
						</Col>
						<Col sm={12}>
							<Title>SMALL & LARGE FORMAT</Title>
							<Text>
								We offer all of the small format print that you would expect from a printer, but we
								also offer large format as well, and that’s also made in-house at our Battersea
								production centre. Together with Yellow Signs we’re able to offer all types of print
								from the smallest business card or label to huge indoor or outdoor signage, so
								whatever you’re looking for, we’ve got it covered!
							</Text>
						</Col>
						<Col>
							<img
								src="./img/printsolution/print-solution-cut-2.jpg"
								alt="Yellow Signs"
								style={{
									position: "absolute",
									height: "180%",
									right: "0",
								}}
							/>
						</Col>
						<Col sm={12}>
							<Title>DIGITAL & LITHO</Title>
							<Text>
								Over the years print has moved away from long run litho print into smaller run, yet
								higher quality and more bespoke print runs. We cater for both types of print no
								matter what the print run size. Digital is perfect for smaller runs but it’s also
								essential for fast turnaround when you need to see samples of the job which are
								identical to the final print run. Our digital presses are calibrated at least once a
								day and produce incredible colour reproduction. We also offer litho print from just
								2 day turnaround times as well as offering Pantone printing which starts at 5 days.
							</Text>
						</Col>
						<Col sm={12}>
							<Title>FANCY FINISHING</Title>
							<Text>
								Finishing is just as important as the actual print run. Standard finishing includes
								trimming, creasing, folding and laminating, but we also offer foiling, embossing and
								debossing. Foiling is more than just silver and gold, and now comes in a huge of
								colours including neon and matt pigments. Blind embossing and debossing can add a
								real touch of class to your booklets, business cards or presentation boxes.
							</Text>
						</Col>
					</Row>
					<Row style={{ marginTop: "2rem" }}>
						<img
							src="./img/printsolution/print-solution-cut-3.jpg"
							alt="Yellow Signs"
							style={{
								width: "100%",
							}}
						/>
					</Row>
					<Row style={{ marginTop: "2rem" }}>
						<Col lg={4}>
							<img
								src="./img/printsolution/print-solution-cut-4.jpg"
								alt="Yellow Signs"
								style={{
									position: "absolute",
									height: "150%",
									marginLeft: "-14px",
								}}
							/>
						</Col>
						<Col lg={4}>
							<Title>SAME-DAY TURNAROUND</Title>
							<Text>
								We pride ourselves on our incredibly fast turnaround and challenge you to test us!
								Being in Battersea we’re able to print, finish, pack, dispatch and deliver print
								jobs within a matter our hours to most of London, including the West and the City.
								We use bikes, cars and vans for same-day deliveries (fully electric where possible),
								as well as overnight couriers for anything out of London or less urgent orders.
							</Text>
						</Col>
						<Col lg={4}>
							<Title>ATTENTION TO DETAIL</Title>
							<Text>
								We’ve been in the print industry for nearly 20 years and understand the importance
								of attention to detail in order to satisfy our client demands. We work with a wide
								range of clients; from SMEs and non-profit organisations, to large corporate
								companies. We are used to and enjoy the pressure of demanding clients who expect us
								to get it right 1st time and are happy to take extremely tight deadlines.
							</Text>
						</Col>
						<Col lg={4}></Col>
						<Col lg={4}>
							<Title>CONTACT</Title>
							<Text>
								If you have any questions are can’t find what you’re looking for, just call us on
								020 8875 0332, email us info@yellowprint.co.uk or pop in to discuss.
							</Text>
						</Col>
						<Col lg={4}></Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default PrintSolution;
