import React, { useEffect, useState } from "react";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import { services } from "../../data/services";
import { Col } from "react-bootstrap";
import Helmet from "react-helmet";

const HTMLContent = styled.div`
	margin-top: 1rem;
`;

export default function ServiceSingle() {
	const [path, setPath] = useState("");

	useEffect(() => {
		setPath(window.location.pathname.substring(1));
	});

	return (
		<div>
			{services.print_selection.map((item) => (
				<>
					{item.link === path && (
						<>
							<Helmet>
								<title>{item.title} Experts - Yellow Print</title>
								<meta
									name="description"
									content="Leading printing and graphic design company based in Battersea. Our online print shop includes all promotional and marketing print. Order online at the best prices."
								/>
							</Helmet>
							<HeaderSimple heading={item.title} subtitle={item.desc_long} key={item.link} />
						</>
					)}
				</>
			))}

			<Container>
				<Row>
					{services.print_selection.map((item) => (
						<>
							{item.link === path && (
								<>
									{item.gallery.map((pic) => (
										<ServicesGalleryImg key={pic.img} img={pic.img} title={pic.title} />
									))}
									<Col lg={12}>
										<HTMLContent dangerouslySetInnerHTML={{ __html: item.desc_html }} />
									</Col>
								</>
							)}
						</>
					))}
				</Row>
			</Container>
		</div>
	);
}
