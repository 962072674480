import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../fragments/TestimonialsSlider.css";
import styled from "styled-components";
import device from "./common/device";

const SingleTestimonial = styled.div`
	height: 16rem;
	padding: 1.5rem;
	@media ${device.md} {
		height: 18rem;
	}
`;

const Txt = styled.p`
	color: black;
	padding: 1.25rem 0.25rem 0 0.25rem;
	font-style: italic;
	margin-top: 1rem;
	line-height: 1.5;
`;

const Title = styled.h4`
	padding: 0.25rem 0.25rem 1.25rem 0.25rem;
	padding-left: 1rem;
	@media ${device.md} {
		font-size: 0.75rem !important;
		padding: 0.25rem 0.25rem 1.25rem 0rem;
	}
`;

const QuoteIcon = styled.img`
	position: absolute;
	margin: -5rem 0px 0px 7rem;
	width: 6rem;
	height: 6rem;
	@media ${device.md} {
		margin: -5rem 0px 0px 4.6rem;
	}
`;

class TestimonialsSlider extends Component {
	render() {
		var settings = {
			autoplay: true,
			dots: true,
			infinite: true,
			speed: 800,
			slidesToShow: 3,
			slidesToScroll: 1,
			centerMode: true,
			responsive: [
				{
					breakpoint: device.mid,
					settings: {
						slidesToShow: 1,
						infinite: true,
						dots: true,
					},
				},
			],
		};

		const testimonialData = [
			[
				{ title: "J Bristow" },
				{
					text: "Great service and product thanks. And generous. Easy to use website, prompt response to my email, easy payment, delivered ahead of schedule (really appreciated for our wedding booklets) and kindly accommodated a couple extra prints over the standard order size. Thank you.",
				},
			],
			[
				{ title: "Inquizition" },
				{
					text: "I've been using Yellow Print for 4 years now, and they are consistently friendly, fast, helpful, and always well-priced. They do the picture rounds for my pub quizzes and I always get comments on how high the quality is. Fully recommended.",
				},
			],
			[
				{ title: "J Harding" },
				{
					text: "I have had Christmas cards done at Yellow Print for several years now. This year, entirely from my IPad. Fantastically quick response to my request, had proof and delivery to my door within 24 hours! Amazing. These guys are terrific.",
				},
			],
			[
				{ title: "Juliet Lemon Photography" },
				{
					text: "Absolutely brilliant and highly recommended! The lovely bunch at Yellow Print Shop designed and printed big display signs, brochures, flyers and business cards for when my business exhibition at The National Wedding Show at Earls Court last year.  They were great - their in-house designer had such good attention to detail and was ever so patient, despite the multiple amendments I kept on making!",
				},
			],
			[
				{ title: "Battersea Spanish" },
				{
					text: "Exceptional service given to my company Battersea Spanish over the years. Would highly recommend and will continue to use their services!",
				},
			],
		];

		const ListTestimonials = testimonialData.map((item) => (
			<div key={item[0].title}>
				<div
					style={{
						height: "4rem",
						background: "white",
					}}
				></div>
				<SingleTestimonial>
					<QuoteIcon src={"./img/common/quotes.png"} alt="quote-mark" className="faQuote" />
					<Txt className="topBorder">{item[1].text}</Txt>
					<Title className="bottomBorder">{item[0].title}</Title>
				</SingleTestimonial>
			</div>
		));

		return (
			<div
				style={{
					paddingBottom: "3rem",
					width: "114%",
					marginLeft: "-7%",
				}}
				id="testimonials-slider"
			>
				<Slider {...settings}>{ListTestimonials}</Slider>
			</div>
		);
	}
}

export default TestimonialsSlider;
