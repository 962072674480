import { services } from "./services";

export const navigation = [
	{
		title: "Print Selection",
		submenu: services.print_selection,
	},
	{
		title: "Print Solution",
		link: "print-solution",
	},
	{
		title: "Delivery",
		link: "delivery",
	},
	{
		title: "About Us",
		link: "about-us",
	},
	{
		title: "Contact Us",
		link: "contact-us",
	},
];
