import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const SubmenuWrapper = styled.ul`
	list-style: none;
	padding-left: 1rem;
	margin: 0;
	height: ${(props) => (props.submenuHeight === "unset" ? "100%" : props.submenuHeight + "px")};
	opacity: ${(props) => (props.submenuHeight === 0 ? 0 : 1)};
	overflow: hidden;
	transition: 0.3s;
`;

const SubmenuItem = styled(Link)`
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	text-transform: capitalize;
	display: block;
`;

const MenuItem = styled.li`
	text-transform: uppercase;
	font-weight: 600;
	list-style: none;
	padding-top: 1rem;
	& a:first-child {
		padding-top: 1rem;
	}
`;

export default function MobileSubmenu({ props, handleCloseMenu }) {
	const [defaultHeight, setDefaultHeight] = useState(0);
	const [submenuHeight, setSubmenuHeight] = useState("unset");

	const ref = useRef(null);

	useEffect(() => {
		setDefaultHeight(ref.current.offsetHeight);
		setSubmenuHeight(0);
	}, [defaultHeight]);

	function handleSubmenuOpen() {
		if (submenuHeight === 0) {
			setSubmenuHeight(defaultHeight);
		} else {
			setSubmenuHeight(0);
		}
	}

	return (
		<MenuItem>
			<div onClick={handleSubmenuOpen}>
				{props.title} <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: "0.25rem" }} />
			</div>
			<SubmenuWrapper ref={ref} submenuHeight={submenuHeight}>
				{props.submenu.map((submenu, index) => (
					<SubmenuItem key={index} to={submenu.link} onClick={handleCloseMenu}>
						{submenu.title}
					</SubmenuItem>
				))}
			</SubmenuWrapper>
		</MenuItem>
	);
}
