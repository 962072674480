import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import FontFaceObserver from "fontfaceobserver";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

// To avoid FOUT the page is rendered only when custom fonts are downloaded
const fontAlternate = new FontFaceObserver("Alternate");
const fontAvenir = new FontFaceObserver("Avenir");

/**
 * Render App
 */

Promise.all([fontAlternate.load(), fontAvenir.load()]).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
});
