import React from "react";
import Helmet from "react-helmet";
import PageBannerA from "../fragments/PageBannerA";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

const Title = styled.h3`
	color: var(--ys-teal);
`;

const Text = styled.p`
	display: block;
	& ul {
		padding-top: 1rem;
	}
`;

const Accent = styled.span`
	font-weight: 600;
	color: var(--ys-teal);
`;

const Italic = styled.i``;

export default function AboutUs() {
	return (
		<>
			<Helmet>
				<title>About Us - Yellow Print</title>
				<meta
					name="description"
					content="Leading printing and graphic design company based in Battersea. Our online print shop includes all promotional and marketing print. Order online at the best prices."
				/>
			</Helmet>
			<PageBannerA
				line1="About"
				line2="Us"
				img="./img/printsolution/print-solution-1.jpg"
				imgTiny="./img/printsolution/print-solution-1-tiny.jpg"
				width="23.5rem"
			/>
			<Container
				style={{
					margin: "6rem auto",
				}}
			>
				<Row>
					<Col lg={9}>
						<Title>
							YELLOW PRINT IS A FAMILY OWNED AND RUN BUSINESS WITH A REAL DESIRE TO PROVIDE A
							SOLUTION-LED APPROACH AND ‘CAN-DO’ ATTITUDE.
						</Title>
						<Text>
							Established in 2005, Yellow Print started in Southfields, quickly opening further
							branches in Shepherd’s Bush and East Sheen. Over the years print moved online and
							print buyer’s priorities changed so did Yellow Print and in 2022 moved from high
							street retail premises to a consolidated purpose designed and built production centre
							and head office in the heart of Battersea, South-West London. Yellow Print works
							alongside Yellow Signs to provide a complete print, graphics and signage solution with
							the majority of jobs made in-house in Battersea.
						</Text>
						<Text>
							Together with state-of-the-art equipment and machinery and a strong network of tried
							and trusted suppliers, Yellow Print and Yellow Signs meet and exceed client
							expectations. In-house production is at the heart of production and the ability
							turnaround jobs quickly and efficiently.
						</Text>
						<Text>
							Same-day and next day dispatch and delivery is available on most products and
							attention to detail is always of upmost importance to ensure jobs are correct and on
							time, first time.
						</Text>
						<Text>
							Yellow Print is socially aware and aims to contribute to the local community causes
							where possible. Yellow Print also donates to WaterAid which is the UK’s dedicated
							water charity, bringing clean water to those who need it most. You can find more
							information here: <a href="https://www.wateraid.org/uk/">wateraid.org/uk</a>
						</Text>
						<Text>
							Feel free to pop into the Yellow Print head office and production centre in Battersea
							to discuss your next project or speak to a member of the team on 020 8875 0332, or
							alternatively email <a href="mailto:info@yellowprint.co.uk">info@yellowprint.co.uk</a>
						</Text>
					</Col>
				</Row>
			</Container>
		</>
	);
}
